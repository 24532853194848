// Import CoreUI styles
@import 'scss/index';

.cursor-pointer {
  cursor: pointer;
}

.w-100 {
  width: 100%;
}

// Import CoreUI styles
@import './scss/index';

.cursor-pointer {
  cursor: pointer;
}

.w-100 {
  width: 100%;
}
