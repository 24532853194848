/*Product Sans*/

@font-face {
  font-family: 'Product Sans';
  src: url('../static/fonts/Product-Sans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Product Sans';
  src: url('../static/fonts/Product-Sans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

/*Product Sans*/

/*Roboto*/

@font-face {
  font-family: 'Roboto';
  src: url('../static/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../static/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

/*Roboto*/

/*Inter*/

@font-face {
  font-family: 'Inter';
  src: url('../static/fonts/Inter/Inter-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../static/fonts/Inter/Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../static/fonts/Inter/Inter-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../static/fonts/Inter/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../static/fonts/Inter/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../static/fonts/Inter/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../static/fonts/Inter/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../static/fonts/Inter/Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../static/fonts/Inter/Inter-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

/*Inter*/
