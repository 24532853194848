.theme.shadow {
  &.shadow-outlined {
    @include box-shadow(0px, 0px, 0px, 1px, $otherDivider, true, true);
    border: 2px solid #ffffff;
    @include transition(0.25s);

    &.selected,
    &:hover {
      @include box-shadow(
        0px 1px 1px,
        1px,
        rgba(100, 116, 139, 0.06),
        false,
        false
      );
      border: 2px solid $bgSecondaryMain;
    }
  }
}
