@mixin sa-config($state,$bgColor) {
    &.#{$state}{
        .sa-close-btn{
            background-color: $bgColor !important;
            &:hover{
                background-color: $bgColor !important;
            }
        }
    }
}

.sweet-alert{
    .sa-close-btn{
        border: none !important;
        outline: 0 !important;
    }

    @include sa-config('error', $errorMain);
    @include sa-config('failure', $errorMain);
    @include sa-config('success', $bgSecondaryMain);
}
