
.divider-bottom {
  border-bottom: 1px solid $otherDivider;
}

.MuiInputBase-root {
  border-radius: 8px;
  //border: 1px solid #d1d5db;
}

// For mui input select dropdown
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper,
.dropdown-shadow {
  box-shadow: 0px 10px 10px rgba(31, 41, 55, 0.04),
    0px 20px 25px rgba(31, 41, 55, 0.1) !important;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: rgba(16, 185, 129, 0.08) !important;
  &:hover {
    background-color: rgba(16, 185, 129, 0.2) !important;
  }
}
// For mui input select dropdown

// For mui date picker input
.MuiOutlinedInput-root .MuiIconButton-root {
  background: transparent !important;
  position: absolute;
  width: 100% !important;
  left: 0;
  padding: 27px 15px;
  display: flex;
  justify-content: flex-end;
  &:hover {
    color: $bgSecondary !important;
  }
  &:focus {
    outline: 0 !important;
  }
}
// For mui date picker input
.MuiAutocomplete-inputRoot {
  padding-right: 0px !important;
}

.main-box {
  min-height: 100vh;
  width: 100%;
  h1 {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    text-transform: capitalize;
    line-height: 137.5%;
    color: #111827;
  }
}

.MuiInputLabel-formControl.Mui-focused:not(.Mui-error) {
  color: $bgSecondary !important;
}

.MuiOutlinedInput-root.Mui-focused:not(.Mui-error) input {
  border-color: $bgSecondary !important;
}

.MuiTooltip-popper {
  &[data-popper-placement*='top'] {
    > div {
      background-color: $bgSecondaryMain !important;
      color: white !important;
      > span {
        color: $bgSecondaryMain !important;
      }
    }
  }
}

.bg-SecondaryMain{
  background-color: $bgSecondaryMain;
}

.border-radius-top{
  border-radius: 10px 10px 0px 0px;
}

.border-radius-bottom{
  border-radius: 0px 0px 10px 10px;
}

.border-radius-top-left{
  border-radius: 10px 0px 0px 0px;
}

.border-radius-top-right{
  border-radius: 0px 10px 0px 0px;
}

.border-radius-bottom-right{
  border-radius: 0px 0px 0px 10px;
}

.border-radius-bottom-left{
  border-radius: 0px 0px 10px 0px;
}


body > iframe:not([src]){
  display: none !important;
}

.no-border{
  border:none !important;
}

#menu-route_type,#menu-cabin_class,.MuiPopperUnstyled-root[role="dialog"]{
  z-index: 9999 !important;
}

.review-option-box{
  &.disabled{
    border: none !important;
    background: #ccccccab;
  }
  &.coming-soon{
    position: relative;
    &:after{
      content: "Coming soon";
      background: #0B815A;
      color: white;
      border-radius: 0px 0px 0px 8px;
      font-size: 12px;
      padding: 5px;
      position: absolute;
      top: 0;
      right: 0;
      font-weight: 600;
    }
  }
}
