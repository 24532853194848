@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}

@mixin font-weighter($min, $max, $step) {
  #{if(&, '&.weight-#{$min}','.weight-#{$min}')} {
    font-weight: $min * 1;
  }
  @if $min < $max {
    @include font-weighter($min + $step, $max, $step);
  }
}

@mixin widther($min, $max, $step) {
  .w-#{$min} {
    width: $min * 1%;
  }
  @if $min < $max {
    @include widther($min + $step, $max, $step);
  }
}

@mixin marginer($min, $max, $step) {
  .mt-#{$min}px {
    margin-top: $min * 1px;
  }
  .mb-#{$min}px {
    margin-bottom: $min * 1px;
  }
  .ml-#{$min}px {
    margin-left: $min * 1px;
  }
  .mr-#{$min}px {
    margin-right: $min * 1px;
  }
  @if $min < $max {
    @include marginer($min + $step, $max, $step);
  }
}

@mixin paddinger($min, $max, $step) {
  .pt-#{$min}px {
    padding-top: $min * 1px;
  }
  .pb-#{$min}px {
    padding-bottom: $min * 1px;
  }
  .pl-#{$min}px {
    padding-left: $min * 1px;
  }
  .pr-#{$min}px {
    padding-right: $min * 1px;
  }
  @if $min < $max {
    @include paddinger($min + $step, $max, $step);
  }
}

@mixin fontsizer($min, $max, $step) {
  .fs-#{$min}px {
    font-size: $min * 1px;
  }
  @if $min < $max {
    @include fontsizer($min + $step, $max, $step);
  }
}

@mixin nobreak {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin rounded($radius: 0.5em) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin box-shadow(
  $top,
  $left,
  $blur,
  $spread: 1px,
  $color,
  $inset: false,
  $important: false
) {
  @if $important {
    @if $inset {
      -webkit-box-shadow: inset $top $left $blur $spread $color !important;
      -moz-box-shadow: inset $top $left $blur $spread $color !important;
      box-shadow: inset $top $left $blur $spread $color !important;
    } @else {
      -webkit-box-shadow: $top $left $blur $spread $color !important;
      -moz-box-shadow: $top $left $blur $spread $color !important;
      box-shadow: $top $left $blur $spread $color !important;
    }
  } @else {
    @if $inset {
      -webkit-box-shadow: inset $top $left $blur $spread $color;
      -moz-box-shadow: inset $top $left $blur $spread $color;
      box-shadow: inset $top $left $blur $spread $color;
    } @else {
      -webkit-box-shadow: $top $left $blur $spread $color;
      -moz-box-shadow: $top $left $blur $spread $color;
      box-shadow: $top $left $blur $spread $color;
    }
  }
}

@mixin transition($time) {
  -webkit-transition: $time;
  -moz-transition: $time;
  -o-transition: $time;
  transition: $time;
}

@mixin transform($transformation) {
  -webkit-transform: $transformation;
  -moz-transform: $transformation;
  -ms-transform: $transformation;
  -o-transform: $transformation;
  transform: $transformation;
}

@mixin transform-style($style) {
  -webkit-transform-style: $style;
  -moz-transform-style: $style;
  -ms-transform-style: $style;
  -o-transform-style: $style;
  transform-style: $style;
}
