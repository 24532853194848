.theme {
  & {
    &.h1,
    &.h2,
    &.h3,
    &.h4,
    &.h5,
    &.h6,
    &.body2 {
      font-family: 'Inter', 'sans-serif';
      font-style: normal;
      margin: 0;
    }
  }

  &.h4 {
    font-weight: 700;
    font-size: 32px;
    line-height: 137.5%;
    color: $textPrimary;
  }

  &.h5 {
    font-weight: 600;
    font-size: 24px;
    line-height: 137.5%;
  }

  &.h6 {
    font-weight: 600;
    font-size: 18px;
    line-height: 137.5%;
    color: $textPrimary;
    &.mini {
      font-size: 12px;
      font-weight: 400;
    }
  }

  &.body1 {
    font-weight: 600;
    font-size: 17.28px;
    line-height: 24px;
  }

  &.body2 {
    font-weight: 400;
    font-size: 14px;
  }

  &.subtitle1 {
    font-weight: 500;
    font-size: 16px;
    line-height: 175%;
  }

  &.subtitle2 {
    font-weight: 500;
    font-size: 14px;
    line-height: 157%;
  }

  &.caption {
    font-weight: 400;
    font-size: 12px;
    line-height: 166%;
  }

  &.secondary-main {
    color: $textSecondaryMain;
  }

  &.secondary {
    color: $textSecondary;
  }

  &.primary {
    color: $textPrimary !important;
  }

  &.overline {
    font-weight: 600;
    font-size: 12px;
    line-height: 250%;
  }

  &.neutral400 {
    color: $textNeutral400;
  }

  @include font-weighter(100, 900, 100);
}

@include font-weighter(100, 900, 100);
