// Import CoreUI styles
@import '../../styles/scss/index';

.cursor-pointer {
  cursor: pointer;
}

.w-100 {
  width: 100%;
}

.otp-dialog-title {
  width: 100%;
  margin: 0;
  text-align: center;
}

.otp-input {
  width: 100%;
  margin: 2em 0 1em;


  .otp-input-field {
    width: 3rem !important;
    height: 3rem;
    margin: 0 1rem;
    font-size: 2rem;
    border-radius: 4px;
    background-color: $textNeutral100;
    border: unset;

    &:focus {
      outline: unset;
    }
  }

  .otp-input-field-error {
    border: 1px solid $textError;
  }
}

.otp-action-button {
  background: $bgSecondaryMain !important;

  &:hover {
    background: $bgSecondaryMain !important;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.26) !important;
    box-shadow: none !important;
    background-color: rgba(0, 0, 0, 0.12) !important;
  }

  &:focus {
    outline: none;
  }
}

.otp-action-button-resend {
  color: $bgSecondaryMain !important;

  &:disabled {
    color: transparent !important;
  }

  &:focus {
    outline: none;
  }
}

.otp-action-button-copy {
  background: $bgSecondaryMain !important;
  color: white !important;
  text-transform: none !important;

  &:disabled {
    color: rgba(0, 0, 0, 0.26) !important;
    box-shadow: none !important;
    background-color: rgba(0, 0, 0, 0.12) !important;
  }

  &:hover {
    background: $bgSecondaryMain;
  }

  &:focus {
    outline: none;
  }
}
