.theme.button {
  padding: 0 2em;

  &.no-cap {
    text-transform: none;
  }

  &.load-spin-only {
    &:disabled {
      color: transparent !important;
    }
  }

  &:disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }

  &:focus {
    outline: none;
  }

  /* Colors */
  &.primary-btn {
    background-color: $bgSecondary;
    color: $bgPrimaryContrast;

    &:hover {
      background: $bgSecondary;
    }

  }

  &.primary-err-btn {
    background-color: $errorMain;
    color: $bgPrimaryContrast;

    &:hover {
      background: $errorMain;
    }
  }

  &.green {
    background-color: $bgSecondary;
    padding: 10px 24px;
    border-radius: 8px;

    &:hover {
      background: $bgSecondary;
    }
  }

  &.red {
    background-color: #D14343;
    padding: 10px 24px;
    border-radius: 8px;

    &:hover {
      background: #D14343;
    }
  }

  &.bg-secondary-main {
    background-color: $bgSecondaryMain;
    padding: 10px 24px;
    border-radius: 8px;
  }

  &.dark-green {
    background-color: #0E8074;
    padding: 10px 24px;
    border-radius: 8px;
  }

}
