// Import CoreUI styles
@import 'scss/index';

.cursor-pointer {
  cursor: pointer;
}

.w-100 {
  width: 100%;
}

html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
}

#root {
  height: 100%;
  background: #FAFAFA !important;
  overflow: hidden;
}

.main-section-wrapper {
  width: 100%;
  min-height: calc(100% - 116px);
  // margin-top: 45px;
  display: flex !important;
  flex-direction: column !important;

  .mt-5{
    margin-top: 0px !important;
  }

  h1{

    margin-bottom: 15px !important;
  }
  @media (max-width: 767px) {

    margin-top: 100px;
  .main-box{

    >h1.theme{
      font-size: 30px;
    }
  }

  }
}

.error-text {
  font-family: "Inter";
  font-weight: 400;
  color: $textError;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

.Mui-error {
  font-family: "Inter" !important;
}

$tableBorderRadius: 8px;

.ag-table {
  width: 100%;
  border-collapse: separate !important;
  border-spacing: 0;
  border: solid rgba(55, 65, 81, 0.12) 1px;
  -moz-border-radius: $tableBorderRadius;
  -webkit-border-radius: $tableBorderRadius;
  border-radius: $tableBorderRadius;


  th, td {
    font-family: 'Inter';
    font-size: 14px;
    padding: 1em;
    text-align: left;
    vertical-align: middle;
    border-bottom: solid rgba(55, 65, 81, 0.12) 1px;;
  }

  th {
    font-weight: 600;
    letter-spacing: .5px;
    text-transform: uppercase;
    color: $textPrimary;
  }

  td {
    font-weight: 400;
  }

  tr:last-child td {
    border-bottom: unset;
  }

  th {
    background: linear-gradient(0deg, #F9F9FB, #F9F9FB), #F9F9FB;
    border-radius: $tableBorderRadius $tableBorderRadius 0 0;
  }

  th:first-child {
    -moz-border-radius: $tableBorderRadius 0 0 0;
    -webkit-border-radius: $tableBorderRadius 0 0 0;
    border-radius: $tableBorderRadius 0 0 0;
  }

  th:last-child {
    -moz-border-radius: 0 $tableBorderRadius 0 0;
    -webkit-border-radius: 0 $tableBorderRadius 0 0;
    border-radius: 0 $tableBorderRadius 0 0;
  }

  th:only-child {
    -moz-border-radius: $tableBorderRadius $tableBorderRadius 0 0;
    -webkit-border-radius: $tableBorderRadius $tableBorderRadius 0 0;
    border-radius: $tableBorderRadius $tableBorderRadius 0 0;
  }

  tr:last-child td:first-child {
    -moz-border-radius: 0 0 0 $tableBorderRadius;
    -webkit-border-radius: 0 0 0 $tableBorderRadius;
    border-radius: 0 0 0 $tableBorderRadius;
  }

  tr:last-child td:last-child {
    -moz-border-radius: 0 0 $tableBorderRadius 0;
    -webkit-border-radius: 0 0 $tableBorderRadius 0;
    border-radius: 0 0 $tableBorderRadius 0;
  }

}

.max-main-box {
  max-width: 80%;
  margin: 0 auto 5%;

}
@media (max-width: 1200px) {
  .max-main-box {
    width: 100%;
    max-width: 100%;
    margin: 0 0 5%;
  }
}

.location-pin-wrapper {
    border-radius: 8px;
    width: 24px;
    height: 24px;
    background: rgba(16, 185, 129, 0.08);
    border-radius: 8px;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 16px;
      height: 16px;
      color: #3FC79A;
    }
}
