// BG
$bgSecondary: #3FC79A;
$bgLight: $bgSecondary;
$bgDefault: #F9FAFC;
$bgPrimaryContrast: #FFFFFF;
$bgSecondaryMain: #10B981;
$bgShadow: #10B98114;
$bgSecondaryHover: rgba(16, 185, 129, 0.08);
$bgLightPrimary: #ECF9F5;
$bgSecondaryDark: #0B815A;
$bgAlert:#FFB020;
$bgSuccessAlertContent:linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #14B8A6;
$bgAlertBox:linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), $bgAlert;
$bgPrimary:#111827;
$bgReceiptPending:#e5e7eb;
$bgReceiptSuccess:#cfe8fc;
$bgFailed:#f6d9d9;
$bgSuccess:#d0f1ed;
$bgPending:#fdf2db;
$bgDisable: #6B7280;

// Text
$textPrimary: $bgPrimary;
$textSecondary: #6B7280;
$textSecondaryBold:$bgSecondary;
$textBlack: #000000;
$textPaper: #FFFFFF;
$textSecondaryMain: $bgSecondaryMain;
$textNeutral500: $bgDisable;
$textNeutral400: #9CA3AF;
$textNeutral100: #F3F4F6;
$textError: #d32f2f;
$textGrey100:#F5F5F5;
$textWarning:$bgAlert;
$textPending:$bgAlert;
$textFailed:#D14343;
$textSuccess:#14B8A6;
$textReceiptPending:#4B5563;
$textReceiptSuccess:#467FAC;


// Others
$otherDivider: #E6E8F0;
$dashedBorder:#DEDEDE ;
$secondaryRestingBorder: rgba(16, 185, 129, 0.5);
$alertBoxBoundary:$bgAlert;
$gray: #FAFAFA;

//states
$statesHoverBg: rgba(16, 185, 129, 0.08);
$errorMain: $textFailed;

$color-Success-Background: #E7F8F6;
$color-Success-pills-Background: #E7F8F6;
$color-Success-light: #43C6B7;
$color-Success-main: #14B8A6;
$color-Success-dark: #0E8074;
$color-Success-content: #084A42;

$color-info-Background: #EFF8FE;
$color-Info-pills-Background: #CFE8FC;
$color-info-dark: #467FAC;
$color-info-content: #284963;

$color-Warning-pills-Background: #FFE4BD;
$color-Warning-dark: #845B10;
$color-Warning-content: #66460D;